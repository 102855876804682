.product__media--preview__items--link {
  transition: transform 0.3s ease;
  overflow: hidden; /* Ensure no overflow of zoomed image */
}

.product__media--preview__items--link.zoomed img {
  transform: scale(1.8); /* Adjust the zoom scale as needed */
}
/* Add zoom effect */
.zoomed {
  overflow: hidden;
}

.zoomed img {
  transform: scale(1.5); /* Adjust scale for deeper zoom */
}

img {
  transition: transform 0.3s ease-in-out;
}
