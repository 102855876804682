.Pop {
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.popup-ad {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ff0000; /* Red background */
  color: #ffffff; /* White text */
  border: 2px solid #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  width: 90%;
  max-width: 300px;
  
  text-align: center;
  display: block; /* Show popup by default */
}

.popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-image {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.popup-title {
  font-size: 1em;
  margin-bottom: 10px;
}

.popup-description {
  font-size: 1em;
}

@media only screen and (min-width: 769px) {
 .popup-ad {
    display: block;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ff0000;
    color: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    width: 40%;
    max-width: 600px;
    text-align: center;
  }
  
  .popup-image {
    width: 100%;
    height: 300px; /* Fixed height for desktop */
    /* Ensure the image covers the area without distortion */
    margin-bottom: 10px;
  }
}

/* Mobile */
@media (max-width: 640px) {
  .popup-ad {
    width: 90%;
    max-width: 300px;
    margin-left: -10px;
    
  }
   .popup-image {
    width: 100%;
    height: 270px; /* Fixed height for desktop */
    /* Ensure the image covers the area without distortion */
    margin-bottom: 10px;
  }
}

/* Desktop */
@media (min-width: 1025px) {
  .popup-ad {
    display: block;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ff0000;
    color: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    width: 40%;
    max-width: 600px;
    text-align: center;
  }
  
  .popup-image {
    width: 100%;
    height: 400px; /* Fixed height for desktop */
    /* Ensure the image covers the area without distortion */
    margin-bottom: 10px;
  }
}
