.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.modal {
  display: none; /* Hide modal by default */
}

.modal-open {
  display: block; /* Show modal when open */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 999;
}

.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  z-index: 1000;
  transition: transform 0.3s ease; /* Add transition for smooth animation */
}

.modal-open .modal-content {
  transform: translate(-50%, -50%) scale(1); /* Scale up when modal is open */
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}
.Productcon {
  margin-bottom: -14rem;
  margin-top: -3rem;
}
.homenew {
  margin-top: -3rem;
}
.shophid {
  margin-top: -4rem;
}

@media only screen and (max-width: 480px) {
  .imgcon {
    width: 306px !important;
    margin-left: 55px !important;
    margin-right: 20px;
  }
}

